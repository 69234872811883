<template>
  <v-row class="toolbar-row mb-4" no-gutters>
    <TitleCounter class="mr-4" :config="config.titleCounterConfig" />
    <v-spacer />
    <DataTableSearchbar
      :no-filter="!(config.hasFilterFunctionality ?? true)"
      @update:filter="updateFilter"
      @update:search="updateSearch"
    />
    <v-row class="mr-4 ml-2" justify="end">
      <IconButtonGroup :config="config.iconButtonConfig" />
    </v-row>
    <DataTableActionButton
      v-if="config.actionsButtonConfig"
      :dataTableId="dataTableId"
      :num-selected-elements="numSelectedElements"
      class="mr-3"
      :config="config.actionsButtonConfig ?? []"
    />
  </v-row>
</template>

<script lang="ts">
import DataTableSearchbar from './DataTableSearchbar.vue';
import IconButtonGroup from './IconButtonGroup.vue';
import TitleCounter from './TitleCounter.vue';
import { defineComponent } from 'vue';
import { Toolbar } from '../../types/DataTable';
import DataTableActionButton from '@/components/common/table/DataTableActionButton.vue';

export default defineComponent({
  name: 'DataTableToolbar',
  components: {
    TitleCounter,
    IconButtonGroup,
    DataTableSearchbar,
    DataTableActionButton
  },
  props: {
    dataTableId: {
      type: String
    },
    config: {
      type: Object as () => Toolbar,
      required: true
    },
    numSelectedElements: {
      type: Number,
      required: true
    },
    maxCount: {
      type: Number,
      required: true
    }
  },
  emits: ['update:filter', 'update:search'],
  methods: {
    updateFilter(filter: boolean): void {
      this.$emit('update:filter', filter);
    },
    updateSearch(search: string): void {
      this.$emit('update:search', search);
    }
  }
});
</script>

<style lang="scss" scoped>
.toolbar-row {
  min-width: none;
  white-space: nowrap;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  width: 100%;
}
.layout {
  max-width: fit-content;
  white-space: nowrap;
}
</style>

<template>
  <div>
    <template v-for="item in navigationItems" :key="item.title">
      <v-list-item
        v-if="
          !item.children &&
          navigationItemVisible(item.featureFlags, licensedFeatures)
        "
        :active="currentPath.includes(item.path)"
        :title="$t(item.title)"
        :prepend-icon="item.icon"
        :value="item"
        color="primary"
        @click="$router.push(item.path)"
      />
      <v-list-group
        v-if="
          item.children &&
          navigationItemVisible(item.featureFlags, licensedFeatures)
        "
        :value="item.title"
      >
        <template #activator="{ props }">
          <v-list-item
            :title="$t(item.title)"
            :prepend-icon="item.icon"
            v-bind="props"
            :value="item"
          >
          </v-list-item>
        </template>
        <NavigationGroup :navigation-items="item.children" />
      </v-list-group>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  NavigationItem,
  navigationItemVisible
} from '../../../navigationDrawer/navigationDrawer';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

const NavigationGroup = defineComponent({
  name: 'NavigationGroup',
  props: {
    navigationItems: {
      type: Array<NavigationItem>,
      required: true
    }
  },
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  computed: {
    ...mapState(authenticationStore, ['licensedFeatures']),
    navigationItemVisible(): typeof navigationItemVisible {
      return navigationItemVisible;
    }
  },
  watch: {
    $route: function (to) {
      this.currentPath = to.path;
    }
  }
});
export default NavigationGroup;
</script>
